import React, { FC, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { throttle } from 'lodash';

import useScreenRecognition from 'hooks/useScreenRecognition';
import useStickyObserver from 'hooks/useStikyObserver';
import Button from 'components/common/Button';
import IconCustom from '../IconCustom';
import SubMenuItem from './SubMenuItem';
import { ISubMenu } from './models';
import './SubMenu.scss';

const SubMenu: FC<ISubMenu> = ({ links, settings: { ariaAnchorMenu, mobileTitle } }) => {
  const submenu = useRef<HTMLDivElement>(null);
  const [isSticky] = useStickyObserver(submenu);
  const { isMobile } = useScreenRecognition();
  const [isOpen, setIsOpen] = useState(false);
  const cx = classnames({
    'gs-sub-menu': true,
    'gs-sub-menu--is-sticky': isSticky,
    'gs-sub-menu--is-open': isOpen,
  });

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const hideMenu = () => {
      setIsOpen(false);
    };
    const trottledFunction = throttle(hideMenu, 500);
    window.addEventListener('scroll', trottledFunction);

    return () => window.removeEventListener('scroll', trottledFunction);
  }, []);

  return links?.length ? (
    <nav ref={submenu} className={cx} aria-label={ariaAnchorMenu} data-testid="sub-menu">
      <div className="gs-sub-menu__bg">
        {isMobile ? (
          <Button classes="gs-sub-menu__button" onClick={handleClick} variant="link">
            {mobileTitle}
            <IconCustom icon="chevron-down" />
          </Button>
        ) : null}
        <ul className="gs-sub-menu__list">
          {links.map((item) => (
            <SubMenuItem key={item.name} {...{ ...item, setIsOpen }} />
          ))}
        </ul>
      </div>
    </nav>
  ) : null;
};

export default SubMenu;
