import React, { FC } from 'react';
import { graphql } from 'gatsby';

import SymptomSection from 'components/SymptomSection';
import TopBanner from 'components/TopBanner';
import Layout from 'components/Layout';
import SubMenu from 'components/common/SubMenu';
import RelatedArticles from 'components/RelatedArticles';
import BodyRenderer from 'components/common/BodyRenderer';
import Testimonials from 'components/Testimonials';
import WhereToBuy from 'components/WhereToBuy';
import StoreFinderRedirect from 'components/StoreFinderRedirect';
import GavirepairBlock from 'components/GavirepairBlock';
import { getSettingsPropsByStructure } from 'utils/parseHelpers';
import TextBlock from 'components/common/TextBlock';
import BreadCrumbs from 'components/common/BreadCrumbs';
import { TSymptomPageComponentProps } from './models';
import './SymptomPage.scss';

const elements = {
  'Related Articles': ({ properties }, keyId) => <RelatedArticles key={keyId} {...properties} />,
  'Testimonials Carousel': ({ properties }, keyId) => <Testimonials key={keyId} {...properties} />,
  'Symptom Block': ({ properties }, keyId) => <SymptomSection key={keyId} {...properties} />,
  'Where to buy': ({ properties }, keyId) => {
    const { whereToBuyLimit, whereToBuyLimitMobile, ...props } = properties;

    return (
      <WhereToBuy
        key={keyId}
        {...{
          limit: whereToBuyLimit,
          limitMobile: whereToBuyLimitMobile,
          ...props,
        }}
      />
    );
  },
  'Store Finder Redirect': ({ properties }, keyId) => (
    <StoreFinderRedirect key={keyId} {...properties} />
  ),
  'Gavirepair Block': ({ properties }, keyId) => <GavirepairBlock key={keyId} {...properties} />,
  'Text Block': ({ properties: { content } }, keyId) => (
    <div className="container-fluid">
      <TextBlock key={keyId} text={content} />
    </div>
  ),
};

const SymptomPage: FC<TSymptomPageComponentProps> = ({
  data: {
    page: {
      nodes: [pageData],
    },
    commonSettings,
    siteSettings,
    relatedArticles,
    recommendedProducts: products,
    header,
    footer,
  },
  pageContext: { breadCrumbs },
}) => {
  const {
    subMenu,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoExternalHreflangs,
    openGraphImageUrl,
    anchorMenu: [{ properties: anchorMenuSettings }],
    topBanner: [{ properties: topBannerData }],
    body,
    showBreadcrumbs,
  } = pageData;

  const articles = relatedArticles?.nodes;
  const videoSettings = getSettingsPropsByStructure(commonSettings, 'Video Modal');

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoExternalHreflangs,
        openGraphImageUrl,
      }}
      siteSettings={siteSettings}
      commonSettings={commonSettings}
      header={header}
      footer={footer}
    >
      <BreadCrumbs data={breadCrumbs} showBreadcrumbs={showBreadcrumbs} />
      <TopBanner {...topBannerData} classes="symptom-top-banner" />
      <SubMenu links={subMenu} settings={anchorMenuSettings} />

      <BodyRenderer
        bodyData={body}
        bodyStructure={elements}
        bodyItemProps={{ ...{ articles, videoSettings, products } }}
      />
    </Layout>
  );
};

export const query = graphql`
  query MyQuery(
    $recommendedProducts: [String]
    $relatedArticlesLinks: [String]
    $link: String = ""
    $lang: String
    $articleSortInput: UmbracoArticlesSortInput
  ) {
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    commonSettings(lang: { eq: $lang }) {
      blocks {
        structure
        properties {
          ...FragmentDefaultCommonSettingsProps
          preTitle
          minText
          secText
          closeModalText
          backBtnText
          dateText
          socialLinks {
            properties {
              ariaLabel
              show
              name
            }
          }
          limit
          ariaLabel
        }
      }
    }
    header(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footer(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    page: allSymptom(filter: { link: { eq: $link }, lang: { eq: $lang } }) {
      nodes {
        seoMetaTitle
        seoMetaDescription
        seoMetaKeywords
        seoExternalHreflangs {
          key
          value
        }
        openGraphImageUrl
        id
        link
        showBreadcrumbs
        relatedArticlesLinks
        anchorMenu {
          properties {
            mobileTitle
            ariaAnchorMenu
          }
        }
        topBanner {
          ...FragmentTopBanner
        }
        title
        body {
          ... on TRelatedArticlesStructure {
            structure
            properties {
              title
              limit
              anchorName
              showInMenu
              menuTitle
            }
          }
          ... on TTestimonialsCarouselStructure {
            structure
            properties {
              color
              title
              slides {
                properties {
                  imageAlt
                  image {
                    ...FragmentGatsbyImage
                  }
                  description
                  ariaLink
                  link {
                    name
                    url
                  }
                }
              }
            }
          }
          ... on TStoreFinderRedirectStructure {
            structure
            properties {
              ariaButton
              button
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              label
              queryString
              title
            }
          }
          ... on TWheretobuyStructure {
            structure
            properties {
              color
              title
              listMode
              menuTitle
              anchorName
              showInMenu
              whereToBuyLimit: limit
              whereToBuyLimitMobile: limitMobile
              cards {
                properties {
                  imageAlt
                  type
                  title
                  link {
                    url
                    target
                    queryString
                  }
                  image {
                    ...FragmentGatsbyImage
                  }
                }
              }
            }
          }
          ... on TTextBlockStructure {
            structure
            properties {
              content
            }
          }
          ... on TSymptomBlockStructure {
            properties {
              title
              description
              imageAlt
              mobileImageAlt
              imageAlign
              menuTitle
              anchorName
              showInMenu
              image {
                ...FragmentGatsbyImage
              }
              mobileImage {
                ...FragmentGatsbyProps
                gatsbyImage {
                  ...FragmentMiddleImage
                }
                mobileImage {
                  childImageSharp {
                    fluid(maxHeight: 204) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              panelBlocks {
                properties {
                  list {
                    properties {
                      customFakeLink
                      scrollTarget
                      hideVariant
                      link {
                        url
                      }
                    }
                  }
                  color
                  text
                  title
                  isFamily
                  link
                  videoPreviewPosterAlt
                  videoPreviewPoster {
                    ...FragmentGatsbyImage
                  }
                  playButtonAria
                  isExpanded
                  items {
                    properties {
                      answer
                      question
                      icon
                    }
                    structure
                  }
                }
                structure
              }
              color
            }
            structure
          }
          ... on TGavirepairBlockStructure {
            properties {
              titleImage {
                ...FragmentGatsbyImage
              }
              titleImageAlt
              description
              image {
                ...FragmentGatsbyImage
              }
              imageAlt
              listImage {
                ...FragmentGatsbyImage
              }
              listImageAlt
              listImagePosition
              listItemMarkerImage {
                fallbackUrl
                url
              }
              list
              footerDescription
            }
            structure
          }
        }

        tags {
          id
          name
        }
        subMenu {
          name
          linkToSection
        }
      }
    }
    recommendedProducts: allProduct(filter: { link: { in: $recommendedProducts } }) {
      nodes {
        link
        productImage {
          ...FragmentGatsbyCardImage
        }
        linkFamily {
          url
        }
        imageFamily {
          ...FragmentGatsbyCardImage
        }
        productImageAlt
        cardTitle
        familyCardTitle
        tickItems {
          value
        }
        tags {
          isProductFamily
          color {
            label
          }
        }
        featureItems {
          properties {
            icon
            label
          }
        }
        cartFakeLink
        currentFormat {
          properties {
            label
          }
        }
      }
    }
    relatedArticles: allUmbracoArticles(
      filter: { link: { in: $relatedArticlesLinks } }
      sort: $articleSortInput
    ) {
      nodes {
        id
        link
        title
        shortTitle
        label
        text
        alt
        shortText
        readMore
        image {
          ...FragmentGatsbyProps
          gatsbyImage {
            ...FragmentSmallImage
          }
          mobileImage {
            childImageSharp {
              fluid(maxHeight: 210) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        tags {
          articleCategory
          id
          name
        }
      }
    }
  }
`;

export default SymptomPage;
